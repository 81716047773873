import React from 'react';
import './styles/home.scss';
import img_mark from '../assets/images/mark.jpg';
import ImageWithFocalPoint from '../components/img-focal-point'

export default () => (
	<main>
		<ImageWithFocalPoint img={img_mark} up={0} right={0} down={4} left={0} />
		<hr className='yellow'/>
		<article>
			<p>
				Hey there!&nbsp;&nbsp;I'm Mark, a full stack developer based out of Toronto, Canada.&nbsp;&nbsp;I make websites!&nbsp;&nbsp;Fairly new to this all, but I'm absolutely here to stay.
				<br /><br />
				Would love to work with you! 🇨🇦✌️
			</p>
		</article>
	</main>
)
