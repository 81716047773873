import React from 'react';
import './styles/img-focal-point.scss';
import './styles/img-focal-point.css';

class ImageWithFocalPoint extends React.Component {
  findFocalPointClasses() {
    let string = '';

    if (this.props.up !== 0){
      string += `up-${this.props.up}`;
    }
    else if (this.props.down > 0){
      string += `down-${this.props.down}`;
    }

    if (this.props.right !== 0){
      string += ` right-${this.props.right}`;
    }
    else if (this.props.left > 0){
      string += ` left-${this.props.left}`;
    }

    return string;
  }

  render(){
    return (
      <div className={`focal-point ${this.findFocalPointClasses()}`}>
        <div>
          <img src={this.props.img} alt='hello, this is mark 🐶' />
        </div>
      </div>
    )
  }
}

export default ImageWithFocalPoint;
